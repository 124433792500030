<template>
    <form autocomplete="off" class="item-info-form">
        <div class="row g-3">
            <div class="col-md-12">
                <h5>Enter Purchase Details</h5>
                <p>Enter in your purchase details as required in order to protect your item</p>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Item Identification
                </label>
                <div class="input-group">
                    <select @change="identityChange()" style="max-width:100px;" v-model="required_key" class="form-select" id="inputGroupSelect02">
                        <option selected value="">-type-</option>
                        <option value="imei">IMEI</option>
                        <option value="serial">Serial</option>
                    </select>
                    <input v-if="required_key == 'serial'" type="text" v-model="data.serial_number" class="form-control"
                        name="serial_number" placeholder="Serial number" />
                    <input v-if="required_key == 'imei'" type="text" v-model="data.imei_number" class="form-control"
                    name="imei_number" placeholder="IMEI number" />
                </div>
            </div>
            <div class="col-md-6">
                <label>Do you pay in installments</label>
                <div class="btn-group w-100">
                    <input type="radio" v-model="data.installmental" value="Yes" class="btn-check" name="options-installment" id="installment-yes-radio" autocomplete="off">
                    <label class="btn btn-outline-primary" for="installment-yes-radio">Yes</label>
                    
                    <input type="radio" v-model="data.installmental" value="No" class="btn-check" name="options-installment" id="installment-no-radio" autocomplete="off">
                    <label class="btn btn-outline-primary" for="installment-no-radio">No</label>
                </div>
                <div v-for="(item, index) in v$.data.installmental.$errors" :key="index"
                    class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Condition when purchased</label>
                <select class="form-select" v-model="data.purchase_condition"
                    name="purchase_condition" placeholder="--select condition--"
                    :class="{ 'is-invalid': v$.data.purchase_condition.$error}">
                    <option disabled value="">--select condition--</option>
                    <option value="BRAND_NEW">Brand new</option>
                    <option value="FAIRLY_USED">Fairly used</option>
                </select>
                    <div v-for="(item, index) in v$.data.purchase_condition.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="purchase_place">
                    Where was it purchased? <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.purchase_place"
                    :class="{ 'is-invalid': v$.data.purchase_place.$error }"
                    id="purchase_place" name="purchase_place" class="form-control"
                    placeholder="Enter place of purchase" />
                <div v-for="(item, index) in v$.data.purchase_place.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="purchase_date">
                    When was it purchase <span class="text-danger small">*</span>
                </label>
                <flat-pickr
                    v-model="data.purchase_date"
                        :config="{wrap: true, 
                        altFormat: 'M j, Y',
                        altInput: true,
                        dateFormat: 'd M, Y',
                        maxDate: new Date()
                    }"
                    class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.purchase_date.$error }"
                ></flat-pickr>
                <div v-for="(item, index) in v$.data.purchase_date.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
             <div class="col-md-6">
                <label class="form-label">
                    Purchase price ($) <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.purchase_price" class="form-control"
                name="purchase_price" :class="{ 'is-invalid': v$.data.purchase_price.$error }"
                    placeholder="0.00" />
                <div v-for="(item, index) in v$.data.purchase_price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
                    
            <div class="col-12">
                <div class="d-flex mt-3">
                    <button @click="resetForm()" class="btn btn-outline-primary me-2" type="button"> Cancel </button>
                    <button class="btn btn-primary" @click.prevent="update()" type="button">Update item</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        flatPickr
    },
    props:{
        items:{
            type: Array,
            default:()=> ([])
        },
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                serial_number: null,
                imei_number: null,
                installmental: "",
                purchase_condition:"",
                purchase_place: "",
                purchase_date: null,
                purchase_price: null
            },
            required_key: "imei"
        }
    },
    validations: {
        data:{
            installmental: {required: required},
            purchase_condition:{required: required},
            purchase_place: {required: required},
            purchase_date: {required: required},
            purchase_price: {required: required}
        }
    },
    methods:{
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/items/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updatedItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        identityChange(){
            this.data.serial_number = this.data.imei_number = null;
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
}
</script>


<style scoped>
.item-info-form .category-item{
    border: 2px solid transparent;
}
.item-info-form .category-item.active{
    border: 2px solid var(--bs-primary);
}
</style>