
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Your Stuff</h5>
      <div v-if="item" class="card mb-5">
        <div class="card-body">
          <div class="row gy-3 justify-content-between align-items-start">
            <div class="col-10 col-sm-8">
              <span>Item ID</span>
              <h6> {{item.friendly_id}} </h6>
            </div>
            <div class="col-2 col-sm-4 text-sm-end">
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="`/subscriptions/${item.subscription_id}`" class="dropdown-item">
                      View subscription </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row pt-3 g-2"> 
            <div class="col-6"> 
              Status <strong v-html="statusBadge(item.status)"> </strong>
            </div>
            <div class="col-sm-6"> 
                <span>Model Name:</span> <strong> {{item.device.model}} </strong>
            </div> 
            <div v-if="item.serial_number" class="col-sm-6"> 
                <span>Serial Number:</span> <strong> {{item.serial_number}} </strong>
            </div>
            <div v-if="item.imei_number" class="col-sm-6"> 
                <span>Imei Number:</span> <strong> {{item.imei_number}} </strong>
            </div>
            <div v-if="item.purchase_date" class="col-sm-6"> 
                <span>Purchase date:</span> <strong> {{$filters.date(item.purchase_date)}} </strong>
            </div>
            <div v-if="item.purchase_price" class="col-sm-6"> 
              <span>Price:</span> <strong> ${{$filters.money_format(item.purchase_price)}} </strong>
            </div>
            <div v-if="item.purchase_condition" class="col-sm-6"> 
              <span>Purchase condition:</span> <strong> {{item.purchase_condition.replace(/_/g, ' ')}} </strong>
            </div>
            <div v-if="item.purchase_place" class="col-sm-6"> 
              <span>Purchase location:</span> <strong> {{item.purchase_place}} </strong>
            </div>
            <div class="col-12"> 
              <hr>
              <h5>Device</h5>
              <div class="d-flex align-items-center justify-content-start">
                <div class="">
                  <img v-if="item.device.image" style="width:3rem; height:3rem; margin-right:1rem;" :src="absoluteUrl(item.device.image)" />
                  <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.device.model.charAt(0).toUpperCase()}}
                    </div>
                  </div>
                </div> 
                <div class="">
                    <h5 class="card-title mb-0">{{item.title}}</h5>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div v-if="!item.purchase_price" class="bd-callout py-2 mt-3 mb-0 bd-callout-warning">
                Fill other details as required in order to protect your item.
                <a class="text-warning fw-bold" @click.prevent="popupModalShow = !popupModalShow" href="#">Update now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.status == 'draft'" class="col-12">
      <h5 class="mb-2">Add item photos</h5>
      <div class="bd-callout py-2 mb-2 mt-0 bd-callout-danger">
        Note: Upload item photos as required in order to protect your item.
      </div>
      <div class="card mb-5">
        <div class="card-body">
          <add-item-photo :itemId="item.id" @closeMe="updateItem($event)" />
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <h5 class="mb-2">Item photos </h5>
      <div class="card mt-2 mb-5">
        <div class="card-body">
          <div class="row g-4">
            <div v-for="(file, fIndex) in item.files" :key="fIndex"
               class="col-12 col-lg-3 col-sm-6" >
              <div>
                <div @click="showImg(fIndex)" class="cursor-pointer">
                  <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                </div>
                <div class="text-center">
                  <span>{{file.description}}</span>
                  <a @click.prevent v-if="item.status == 'draft'" class="ms-2 small" href="#"> <i class="ri ri-image-add-fill align-bottom"> </i> Edit</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-easy-lightbox v-if="item" :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" hide-footer centered
    title="Update item"
    title-class="fs-6" @hide="initializeAll()">
    <item-info-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="item" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import AddItemPhoto from '@/components/forms/AddItemPhoto.vue'
import VueEasyLightbox from "vue-easy-lightbox";
import IsLoading from '@/components/IsLoading.vue'
import ItemInfoForm from '@/components/forms/ItemInfoForm.vue';

export default {
  components:{
    VueEasyLightbox,
    AddItemPhoto,
    ItemInfoForm,
    IsLoading,
  },
  data(){
    return {
      isLoading: true,
      popupModalShow: false,
      item: null,
      lightbox:{
        index: null,
        visible:false
      },
    }
  },
  watch: {
    '$route.params.itemId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    lightboxFiles(){
      return this.item.files.map(file => {
        if(file.is_video){
          return {
            title: file.description,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.description,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.item, item)
    },
    showImg(index) {
      this.lightbox = { index: index,visible: true};
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/items/${this.$route.params.itemId}/show`)
      .then((response) => {
          this.isLoading =  false
          if(response.data.success){
            this.item = response.data.data
            if(!this.item.purchase_price){ this.popupModalShow = true}
          }else{
            this.$router.push({path: "/error-404"})
          }
      })
    },
    initializeAll(){
      this.popupModalShow = false;
      this.lightbox = { index: null, visible:false}
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

<style lang="scss">

</style>
